import {
    WEBSITE_REQUEST,
    WEBSITE_SUCCESS,
    WEBSITE_CLEAR,
    WEBSITE_UPDATECURRENTWEBSITE
} from "../actions/website";
import {apiCall} from "../../utils/api";
//import Vue from "vue";

const state = { 
    websites:  JSON.parse(localStorage.getItem("WebSites")) || [], 
    currentWebSiteObj:  JSON.parse(localStorage.getItem("current-WebSite-obj")) || {}, 
    currentWebSite: localStorage.getItem("current-WebSite") || ""
};

const getters = {
    getWebSites: state => state.websites,
    getCurrentWebSite: state => state.currentWebSite,
    getCurrentWebSiteObj: state => state.currentWebSiteObj
};

const actions = {
    [WEBSITE_REQUEST]: ({ commit }) => {
        return new Promise((resolve, reject) => {
          //commit(WEBSITE_REQUEST);
          apiCall({ url: "WebSites/GetAssociationUserWebSites/", data: {}, method: "GET" })
            .then(resp => {
            //console.log("RISPOSTA WS " + JSON.stringify(resp));

              var WebSites = [];

              for (let i = 0; i < resp.length; i++) {

                let jsonConfig = {};
                try {
                    console.log("resp[i].configurations",resp[i].webSite.configurations);
                    jsonConfig = JSON.parse(resp[i].webSite.configurations);
                }
                catch(e){
                    console.error(e);
                }
                
                  
                var WebSite = {
                    "Name" :  resp[i].webSite.name,
                    "SiteKey": resp[i].webSite.siteKey,
                    "WebSiteId" : resp[i].webSiteId,
                    "Configurations" : jsonConfig || {},
                    "webSiteLanguages" : resp[i].webSite.webSiteLanguages
                }

                WebSites.push(WebSite);
              }

              //Salvo i Dati sul localStorage              
              localStorage.setItem("WebSites", JSON.stringify(WebSites));
              localStorage.setItem("current-WebSite", WebSites[0].WebSiteId);
              localStorage.setItem("current-WebSite-obj", JSON.stringify(WebSites[0]));
            
              commit(WEBSITE_SUCCESS, resp);
           
              resolve(resp);
            })
            .catch(err => {
              //commit(WEBSITE_REQUEST, err);
              localStorage.removeItem("WebSites");
              localStorage.removeItem("current-WebSite");
              localStorage.removeItem("current-WebSite-obj");
              reject(err);
            });
        });
    },
    [WEBSITE_CLEAR]: ({ commit }) => {
        return new Promise(resolve => {
          commit(WEBSITE_CLEAR);
          //Al logout pulisco il local storage dove salvo i dati dei WebSite
          localStorage.removeItem("WebSites");
          localStorage.removeItem("current-WebSite");
          localStorage.removeItem("current-WebSite-obj");
          resolve();
        });
    },
    [WEBSITE_UPDATECURRENTWEBSITE]: ({ commit }, data) => {
            
        //Cancello i vecchi valori
        localStorage.removeItem("current-WebSite");
        localStorage.removeItem("current-WebSite-obj");
        
        //Salvo il valore del nuovo current-WebSite
        localStorage.setItem("current-WebSite", data.newWebSiteId);       
        
        commit(WEBSITE_UPDATECURRENTWEBSITE, data);
    }
}

const mutations = {
    [WEBSITE_REQUEST]: state => {
        state.websites = [];
        state.currentWebSite = "";
        state.currentWebSiteObj = {};
    },
    [WEBSITE_SUCCESS]: (state, resp) => {
        state.websites = JSON.parse(localStorage.getItem("WebSites")) || [];
        state.currentWebSite =  localStorage.getItem("current-WebSite") || "";
        state.currentWebSiteObj =  JSON.parse(localStorage.getItem("current-WebSite-obj")) || {};
    },
    [WEBSITE_CLEAR]: state => {
        state.websites = [];
        state.currentWebSite = "";
        state.currentWebSiteObj = {};
    },
    [WEBSITE_UPDATECURRENTWEBSITE]: (state, data) => {
        state.websites = JSON.parse(localStorage.getItem("WebSites")) || [];
        state.currentWebSite =  data.newWebSiteId; //Aggiorno il valore del current WebSite
        state.currentWebSiteObj = state.websites.find((w) => { return w.WebSiteId == data.newWebSiteId}); //Aggiorno il valore del current WebSite
        localStorage.setItem("current-WebSite-obj", JSON.stringify(state.currentWebSiteObj));
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};
  