const langCodeFlagMapping = {
    en: "gb"
};

const getFlagFromLangCode = ( langCoge ) =>{
    //console.log(langCoge);
     if(Object.prototype.hasOwnProperty.call(langCodeFlagMapping, langCoge)) return langCodeFlagMapping[langCoge];
     return langCoge;
};



export {getFlagFromLangCode};