import axios from 'axios';
import qs from 'qs';
axios.defaults.headers.post['access-control-allow-headers'] = 'access-control-allow-headers,access-control-allow-origin,Content-Type, Accept, Referer, User-Agent';
/*const mocks = {
  auth: { POST: { token: "This-is-a-mocked-token" } },
  "user/me": { GET: { name: "doggo", title: "sir" } }
};

const apiCall = ({ url, method }) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        resolve(mocks[url][method || "GET"]);
        console.log(`Mocked '${url}' - ${method || "GET"}`);
        console.log("response: ", mocks[url][method || "GET"]);
      } catch (err) {
        reject(new Error(err));
      }
    }, 1000);
  });*/

const baseApiUrl = process.env.VUE_APP_API_URL;

/*const apiCall = ({ url, data, method }) =>
   axios({ 
      method: method || "GET",
      url: baseApiUrl + url,
      data: data
  });*/

  axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    let token = localStorage.getItem("user-token") || "";
    if(token != "")
    {
      config.headers["Authorization"] = "Bearer " + token;
    }


    return config;
  });

  
 
const apiCall = ({ url, data, method }) =>
  new Promise((resolve, reject) => {
    axios({ 
      method: method || "GET",
      url: baseApiUrl + url,
      data: method == "GET" ? {} : data,
      params:  method == "GET" ? data : {},
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    }).then(resp => {      
      resolve(resp.data);
    }) 
    .catch(err => {
      reject(new Error(err));
    });    
  });

const uploadFile = ({ url, file }) =>
  new Promise((resolve, reject) => {

    let formData = new FormData();
    //Il nome del campo deve corrispondere al parametro presente nelle api
    formData.append('uploadedFile', file);

    axios({ 
      method: "POST",
      url: baseApiUrl + url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(resp => {      
      resolve(resp.data);
    })
    .catch(err => {
      reject(new Error(err));
    });    
  });



export {baseApiUrl ,apiCall , uploadFile};
