<template>
    <v-overlay :value="blockUI" z-index="100000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
</template>

<script>
import { WEBSITE_UPDATECURRENTWEBSITE } from "../store/actions/website";

export default {
  computed: {
    blockUI () {      
      //console.log(this.$store);
      return this.$store.getters.getblockUI;
    }
  }
}
</script>