<template>
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="validationRules" :disabled="!showUpload">
    
    <v-card
      class="mx-auto"
      flat
      v-cloak
      @drop.prevent="addDropFile"
      @dragover.prevent
      v-if="showUpload"
    >
    
      <v-file-input
        v-model="internalFile"
        :label="name" 
        @change="onFileChange($event)"
        prepend-icon="mdi-cloud-upload-outline"
        :error-messages="errors"
        :accept="extensions"
        
      ></v-file-input>
    </v-card>

    <v-card class="mb-5" v-if="showFileList">
      <v-toolbar dense flat color="secondary" dark>
        <p class="mb-0">Files</p>
      </v-toolbar>
      <v-list-item v-for="(ufile, index) in uploadedFiles" :key="ufile.fileName">
        <v-list-item-content>          
           {{ufile.fileName}}  
        </v-list-item-content>
        <v-list-item-action>
          
          <a :href="GetResource(ufile.fileName)" target="_blank"><v-icon small>mdi-cloud-download</v-icon></a>
        </v-list-item-action>

        <v-list-item-action>
          
          <v-btn icon @click="deleteFile(ufile,index)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-card>
  </ValidationProvider>
</template>

<script>
import CmeaseBaseFieldMixin from "./cmease-base-field-mixin";
import { baseApiUrl,apiCall, uploadFile } from "../../utils/api";
export default {
  mixins: [CmeaseBaseFieldMixin],
  name: "cmease-file-upload-field",
  props: {
    value: {
      default: () => {
        return {};
      },
    },
    multiple: {
      default: () => {
        return false;
      },
    },
    extensions: {
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      files: {},
      internalFile: null,
      uploadedFiles: [],
    };
  },
  mounted() {
    /*console.log(
      "cmease-file-upload-field mounted",
      this.multiple,
      this.value,
      this.internalValue,
      typeof this.internalValue,
      Object.prototype.hasOwnProperty.call(this.internalValue, "fileName")
    );*/
    if (
      !this.multiple &&
      typeof this.internalValue === "object" &&
      Object.prototype.hasOwnProperty.call(this.internalValue, "fileName") &&
      this.internalValue.fileName != ""
    ) {
      this.uploadedFiles.push(this.value);
    }
  },
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated;
    },
    currentWebSite() {
      return this.$store.getters.getCurrentWebSite;
    },
    showUpload() {
      if (!this.multiple && this.uploadedFiles.length > 0) {
        return false;
      }
      return true;
    },
    showFileList() {
      if (!this.multiple && this.uploadedFiles.length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    onFileChange: function (value, key = "") {
      //console.log("onFileChange", value);
      uploadFile({
        url: "WebSites/uploadFile/" + this.currentWebSite,
        file: value,
      })
        .then((resp) => {
          //console.log(resp);
          this.update({ ...resp }, "change", key);

          if (!this.multiple) {
            this.uploadedFiles.push({ ...resp });
          }
        })
        .catch((err) => {});
    },
    addDropFile(e) {
      //console.log(e.dataTransfer.files[0]);
      this.internalFile = { ...e.dataTransfer.files[0] };
    },
    deleteFile(f, i) {
      this.uploadedFiles.splice(i, 1);
    },
    GetResource(fileName){
         return baseApiUrl+'WebSites/downloadFile/'+this.currentWebSite+'?filename='+fileName;
      
    }
  },
};
</script>