import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import {VueFormJsonSchema} from './plugins/form-generator.js';
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/it.json';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import VuetifyConfirm from 'vuetify-confirm'
import './assets/scss/custom.scss';
import CountryFlag from 'vue-country-flag'
 



setInteractionMode('eager')

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Conferma password non corrispondente'
});


Vue.component('ValidationProvider', ValidationProvider);

Vue.component('ValidationObserver', ValidationObserver);

Vue.component('country-flag', CountryFlag)



Vue.use(VueAxios, axios)

Vue.use(VuetifyConfirm, { vuetify,
  buttonTrueText: 'Sì',
  buttonFalseText: 'No',
  icon: 'warning',
  color: "warning", title: "Attenzione"
})

Vue.config.productionTip = false

var vm = new Vue({  
  router,
  store,
  vuetify,
  VueFormJsonSchema,
  render: h => h(App)
}).$mount('#app')
