var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{attrs:{"loading":_vm.firstLoad,"transition":_vm.transition,"tile":true,"type":"table"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.entities,"options":_vm.options,"server-items-length":_vm.totalEntities,"loading":_vm.loading,"item-key":"entityId","show-select":"","single-select":_vm.singleSelect},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.preview",fn:function(ref){
var item = ref.item;
return [(_vm.GetImagePath(item))?_c('v-img',{attrs:{"src":_vm.GetImagePath(item),"height":"50","contain":"","aspect-ratio":"1"}}):_c('v-icon',[_vm._v("mdi-cancel")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.createdAt).toLocaleString()))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.updatedAt).toLocaleString()))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.schema.name))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"change":_vm.getDataFromApiDebounced,"input":_vm.getDataFromApiDebounced},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v("You can use % as a wildcard")])]),_c('v-spacer')],1)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-btn',{staticClass:"mt-4",attrs:{"small":"","color":"secondary"},on:{"click":_vm.select}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.confirmSelection')))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }