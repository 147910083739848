<template>
<ValidationProvider v-slot="{ errors }" :name="name" :rules="validationRules">
    <v-select
            v-model="internalValue"
            :items="items"
            :label="name"
            :required="required"
            :error-messages="errors"            
            :readonly="readonly"
            :disabled="disabled"
            :item-text="itemText"
            :item-value="itemValue"
            single-line
            bottom
            auto
            @change="onChange($event)"
    ></v-select>
    </ValidationProvider>
</template>

<script>
  import CmeaseBaseFieldMixin from './cmease-base-field-mixin'
    
  export default {
    mixins: [CmeaseBaseFieldMixin], 
    name: 'cmease-select-field',
    props: ['items','itemText','itemValue'],  
  }
</script>