<template>
<div>
    <v-checkbox
            :label="name"
            v-model="internalValue"
            @click="onClick(internalValue)"
            :color="color"
    ></v-checkbox>
</div>
</template>

<script>
  import CmeaseBaseFieldMixin from './cmease-base-field-mixin'

  export default {
    mixins: [CmeaseBaseFieldMixin],    
    name: 'cmease-checkbox',
    props: ['color'],
  }
</script>