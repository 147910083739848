<template>
    <ValidationProvider v-slot="{ errors }" :name="name" :rules="validationRules">
                  
      <v-text-field
          :value="internalValue"
          :label="name"
          :required="required"          
          @input="onSlugChange($event)"
          :error-messages="errors"
      ></v-text-field>

    </ValidationProvider>
</template>

<script>
  import CmeaseBaseFieldMixin from './cmease-base-field-mixin'
  import slugify from 'slugify'

  export default {
    mixins: [CmeaseBaseFieldMixin],    
    name: 'cmease-slug-text-field',
    methods: {         
        onSlugChange (value,key="") {     
            this.onInput(slugify(value).toLowerCase(), key)
        }
    }

  }
</script>