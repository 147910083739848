<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app v-if="isAuthenticated">
      <simplebar data-simplebar-auto-hide="false">
        <v-list dense flat>
          <v-list-item-group color="secondary">
            <v-list-item link to="/" class="pb-5 pt-2">
              <v-img max-height="100" contain src="./assets/logo.svg"></v-img>
            </v-list-item>
            <v-list-item link to="/">
              <v-list-item-action class="mr-3">
                <v-icon small>mdi-view-dashboard</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $vuetify.lang.t('$vuetify.dashboard') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-for="s in schemas">
              <v-list-item :key="s.schemaId" link :to="'/Entities/'+s.schemaKey">
                <v-list-item-action class="mr-3">
                  <v-icon small>mdi-{{getIcon(s.schemaType)}}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{s.name}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item link to="/websiteusers">
              <v-list-item-action class="mr-3">
                <v-icon small>mdi-view-dashboard</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $vuetify.lang.t('$vuetify.websiteusers') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/resources">
              <v-list-item-action class="mr-3">
                <v-icon small>mdi-view-dashboard</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $vuetify.lang.t('$vuetify.resourcesLocalization') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link to="/schemas">
              <v-list-item-action class="mr-3">
                <v-icon small>mdi-view-dashboard</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $vuetify.lang.t('$vuetify.schemas') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </simplebar>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logout" outlined color="secondary">
            <v-icon left>mdi-logout</v-icon>Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="primary" dark flat>
      <v-row justify="space-between" align="center">
        <v-col cols="4">
          <v-row align="center" justify="start">
            <v-col cols="2">
              <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="isAuthenticated"></v-app-bar-nav-icon>
            </v-col>
            <v-col cols="10">
              <v-toolbar-title>{{appTitle}}</v-toolbar-title>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <siteSelector v-if="isAuthenticated" @change="loadSchema(isAuthenticated, $event)"></siteSelector>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="start" justify="start" class="fill-height">
          <v-col align-self="start" cols="12">
            <router-view :key="$route.fullPath"></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer color="primary" dark app>
      <span class="text-caption">&copy; 2020 e-Project Srl</span>
      <v-spacer></v-spacer>
      <span class="text-caption">v{{appVersion}}</span>
    </v-footer>
    <blockUI></blockUI>
  </v-app>
</template>

<script>
import { apiCall } from "./utils/api";
import siteSelector from "./components/SiteSelector";
import blockUI from "./components/BlockUI";
import { AUTH_LOGOUT } from "./store/actions/auth";
import { WEBSITE_REQUEST, WEBSITE_CLEAR } from "./store/actions/website";

import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  name: "App",
  components: {
    siteSelector,
    simplebar,
    blockUI
  },
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated;
    },
    WebSites: function () {
      return this.$store.getters.getWebSites;
    },
    currentWebSite: function () {
      return this.$store.getters.getCurrentWebSite;
    },
    baseUrl: function () {
      return process.env.VUE_APP_BASE_URL;
    },
    appTitle() {
      return process.env.VUE_APP_TITLE;
    },
    appVersion() {
      return process.env.VUE_APP_VERSION;
    },
  },
  mounted() {
    this.loadWebSites(this.isAuthenticated, this.WebSites); //Carico i siti Web Gestiti dall'Utente
    this.loadSchema(this.isAuthenticated); //Carico gli schema del sito Web Principale
  },
  watch: {
    isAuthenticated: function (val) {
      this.loadWebSites(val); //Carico i siti Web Gestiti dall'Utente
    },
  },
  data: () => ({
    drawer: false,
    miniVariant: false,
    schemas: [],
  }),
  methods: {
    logout: function () {
      this.$store.dispatch(WEBSITE_CLEAR);

      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push("/login");
      });

      this.$store.dispatch(AUTH_LOGOUT);
    },
    getDataFromApi() {
      this.loading = true;

      let retval = {
        schemas: [],
      };

      return new Promise((resolve, reject) => {
        apiCall({
          url: "Schema/byWebSite/" + this.currentWebSite,
          data: {},
          method: "GET",
        })
          .then((resp) => {
            retval.schemas = resp;
            resolve(retval);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    loadSchema: function (isAuth) {
      if (isAuth) {
        //console.log("API URL", process.env.VUE_APP_API_URL);
        this.getDataFromApi()
          .then((data) => {
            this.schemas = data.schemas;

            //console.log("schemas", this.schemas);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    loadWebSites: function (isAuth, websites) {
      if (isAuth && !websites) {
        //console.log('WEBSITE_REQUEST');
        this.$store.dispatch(WEBSITE_REQUEST).then(() => {
          this.loadSchema(isAuth); //Carico gli schema del sito Web Principale
        });
      }
    },
    getIcon(schemaType) {
      switch (schemaType) {
        case "ASSET":
          return "folder-multiple-image";
        case "CATEGORY":
          return "folder-multiple";
        case "CONTENT":
          return "buffer";
        case "MENU":
          return "file-tree";
        default:
          return "view-list";
      }
    },
  },
};
</script>
