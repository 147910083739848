<template>
  <div>
    <ValidationProvider ref="myProvider" v-slot="{ errors }" :name="name" :rules="validationRules">
      <v-card class="mb-5">
        <v-card-subtitle color="secondary">{{name}}</v-card-subtitle>
        <v-card-text>
          
          <v-alert type="error" v-if="errors.length > 0">
            <div v-for="(er, index) in errors" :key="index">{{er}}</div>
          </v-alert>

          <v-text-field
            :value="internalValue"
            :label="name"
            @input="onInput($event)"
            @change="OnChange($event)"
            :error-messages="errors"
            class="d-none"
          ></v-text-field>

          <v-row v-if="showAvailableSchemaSelect">
            <v-col>
              <v-select
                v-model="selectedSchemaKey"
                :items="availableSchema"
                label="Tipo Elemento"
                item-text="name"
                item-value="schemaKey"
                single-line
                bottom
                auto
              ></v-select>
            </v-col>
          </v-row>

          <v-btn
            small
            color="primary"
            @click="newItem"
            v-if="showNewItem"
            :disabled="selectedSchemaKey == '' "
          >{{ $vuetify.lang.t('$vuetify.new') }}</v-btn>
          <v-btn
            small
            color="primary"
            class="ml-5"
            @click="selectFromList"
            v-if="showNewItem"
            :disabled="selectedSchemaKey == '' "
          >{{ $vuetify.lang.t('$vuetify.select') }}</v-btn>
          <v-btn
            small
            color="primary"
            class="ml-5"
            @click="massiveUpload"
            v-if="showMassiveUploadBtn"
            :disabled="selectedSchemaKey == '' "
          >{{ $vuetify.lang.t('$vuetify.massiveUpload') }}</v-btn>

          <v-list v-if="!dialog">
            <v-list-item-group v-if="internalValue.length > 0">
              <template v-for="(item, index) in internalValue">
                <v-list-item :key="item.entityId">
                  <v-list-item-content>
                    <component
                      :is="getRenderingComponentName(item)"
                      v-bind="{...item}"
                      @edit-item="onEditItem"
                    ></component>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon small @click="moveItemUp(item,index)" v-show="index > 0">
                      <v-icon>mdi-arrow-up</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn icon small @click="moveItemDown(item,index)" v-show="index + 1 < internalValue.length">
                      <v-icon>mdi-arrow-down</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn icon small @click="deleteFile(item,index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-divider v-if="index + 1 < internalValue.length" :key="index+'-divider'"></v-divider>
              </template>
            </v-list-item-group>

            <v-list-item-group v-if="!internalValue || internalValue.length == 0">
              <v-list-item>
                <v-list-item-content>{{ $vuetify.lang.t('$vuetify.noItemsSelected') }}</v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </ValidationProvider>

    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" :retain-focus="false">
      <v-card>
        <entity-edit-form
          v-if="form_loaded"
          v-model="editedItem"
          :schema="schema"
          :is-insert="isInsert"
          @closed="onDialogClosed"
          @saved="onSaved"
        ></entity-edit-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="selectFromListDialog"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar flat dark color="secondary">
          <v-container fluid>
            <v-row align="center" justify="space-between">
              <v-col>
                <v-toolbar-title>{{ $vuetify.lang.t('$vuetify.select') }}</v-toolbar-title>
              </v-col>
              <v-col class="text-right">
                <v-btn icon dark @click="closeSelectFromListDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-toolbar>

        <v-card-text class="mt-5">
            <cmease-entity-selector-field-entities-list
              :schemaKey="selectedSchemaKey"
              v-if="selectFromListDialog"
              :single-select="singleSelect"
              :quantity="quantity"
              @select="onSelect"
            ></cmease-entity-selector-field-entities-list>
        </v-card-text>

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeSelectFromListDialog">Cancel</v-btn>
        </v-card-actions> -->

      </v-card>
    </v-dialog>

    <v-dialog
              v-model="showAssetsMassiveUploadsDialog"              
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              :retain-focus="false"
            >
              
              <v-card>
              <assets-massive-uploads
                v-if="showAssetsMassiveUploadsDialog"
                :schema="schema"
                @closed="onDialogClosed"
                @saved="onSaved"
                @select="onSelect"
                :showConfirmSelectionBtn=true
              ></assets-massive-uploads>
              </v-card>
            </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import CmeaseBaseFieldMixin from "./cmease-base-field-mixin";
import { apiCall } from "../../utils/api";
//import PrettyPrint from "../components/pretty-print";
import EntityEditForm from "../EntityEditForm";
import { BLOCK_UI, UN_BLOCK_UI } from "../../store/actions/application";
import AssetsMassiveUploadsVue from '../../components/AssetsMassiveUploads.vue';
import _ from 'lodash';

export default {
  mixins: [CmeaseBaseFieldMixin],
  name: "cmease-entity-selector-field",
  props: {
    schemaKey: {
      type: String,
      default: "",
    },
    entityType: {
      type: String,
      default: "",
    },
    relationshipType: {
      type: String,
      default: "",
    },
    selectorRenderer: {
      type: String,
      default: "",
    },
    exportRelationshipToDb: {
      type: Boolean,
      default: false,
    },
    quantity: {
      type: Number,
      default: 1,
    },
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  components: {
    //"pretty-print": PrettyPrint,
    "entity-edit-form": EntityEditForm,
    "assets-massive-uploads": AssetsMassiveUploadsVue,
  },
  data() {
    return {
      tab: null,
      blockModal: false,
      dialog: false,
      selectFromListDialog: false,
      totalEntities: 0,
      schema: {},
      entities: [],
      firstLoad: true,
      loading: true,
      form_loaded: false,
      transition: "scale-transition",
      options: {},
      editedItem: {},
      state: {},
      isInsert: false,
      selectedSchemaKey: "",
      availableSchema: [],
      showAvailableSchemaSelect: false,    
      showAssetsMassiveUploadsDialog: false,  
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi().then((data) => {
          this.schema = data.schema;
        });
      },
      deep: true,
    }
  },
  computed: {
    isUpdate: function () {
      return !this.isInsert; // this.editedIndex > -1;
    },
    currentWebSite() {
      return this.$store.getters.getCurrentWebSite;
    },
    showNewItem() {
      return this.internalValue.length < this.quantity;
    },
    showMassiveUploadBtn(){
      let tempShow = false;
      for (let index = 0; index < this.availableSchema.length; index++) {
          const schema = this.availableSchema[index];

          if(schema.schemaKey == this.selectedSchemaKey && schema.schemaType === "ASSET")
          {
            tempShow = true;
            break;
          }
          
      }
      return this.showNewItem && !this.singleSelect && tempShow;
    },
    singleSelect() {
      return this.quantity == 1;
    },
  },
  mounted() {
    if (this.schemaKey == "") {
      this.getAvailableSchemaFromApi().then((data) => {
        this.availableSchema = data.schema;
        console.log("availableSchema",this.availableSchema);
        this.availableSchema.unshift({
          name : "Seleziona una tipologia di elemento",
          schemaKey: ""
        });

        if (this.availableSchema.length > 0) {
          this.selectedSchemaKey = this.availableSchema[0].schemaKey;
        }

        this.showAvailableSchemaSelect = true;

        //console.log("mounted", this.selectedSchemaKey, this.availableSchema);
      });
    } 
    else if (this.schemaKey.indexOf(",") > -1) {
      this.getAvailableSchemaFromApi().then((data) => {
        this.availableSchema = [];
        var configSchemas = this.schemaKey.split(",");

        for (let index = 0; index < data.schema.length; index++) {
          const schema = data.schema[index];

          if(configSchemas.indexOf(schema.schemaKey) > -1)
          {
            this.availableSchema.push(schema);
          }
          
        }

        this.availableSchema.unshift({
          name : "Seleziona una tipologia di elemento",
          schemaKey: ""
        });

        if (this.availableSchema.length > 0) {
          this.selectedSchemaKey = this.availableSchema[0].schemaKey;
        }

        this.showAvailableSchemaSelect = true;

        //console.log("mounted", this.selectedSchemaKey, this.availableSchema);
      });
    }
    else {
      this.selectedSchemaKey = this.schemaKey;
      this.getAvailableSchemaFromApi().then((data) => {
        this.availableSchema = [];

        for (let index = 0; index < data.schema.length; index++) {
          const schema = data.schema[index];

          if(this.schemaKey.toLowerCase() === schema.schemaKey.toLowerCase())
          {
            this.availableSchema.push(schema);
          }
          
        }

        //console.log("mounted 3",this.schemaKey, this.selectedSchemaKey, this.availableSchema);
      });
    }
  },
  beforeUpdate() {
    //console.log("beforeUpdate");
  },
  methods: {
    onChangeState(value) {
      this.state = value;
    },
    getAvailableSchemaFromApi() {
      this.loading = true;

      let webSiteId = this.$store.getters.getCurrentWebSite;

      return new Promise((resolve, reject) => {
        /*apiCall({
          url: "Schema/byType/" + webSiteId + "/" + this.entityType,
          data: {},
          method: "GET",
        })
          .then((resp) => {
            resolve({
              schema: resp,
            });
          })
          .catch((err) => {
            reject(err);
          });*/
///TODO: trovare un sistema migliore

          apiCall({
          url: "Schema/byWebSite/" + webSiteId,
          data: {},
          method: "GET",
        })
          .then((resp) => {
            resolve({
              schema: resp,
            });
          })
          .catch((err) => {
            reject(err);
          });

      });
    },
    getDataFromApi() {
      this.loading = true;

      return new Promise((resolve, reject) => {
        apiCall({
          url: "Schema/byKey/" + this.selectedSchemaKey,
          data: {},
          method: "GET",
        })
          .then((resp) => {
            resolve({
              schema: resp,
            });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getEmptyDataFromApi(webSiteId, schemaId) {
      return new Promise((resolve, reject) => {
        apiCall({
          url:
            "Entities/empty?webSiteId=" + webSiteId + "&schemaId=" + schemaId,
          data: {},
          method: "GET",
        })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    newItem() {
      this.form_loaded = false;
      this.editedIndex = -1;
      this.isInsert = true;

      let webSiteId = this.$store.getters.getCurrentWebSite;

      this.getDataFromApi().then((data) => {
        this.schema = data.schema;

        this.getEmptyDataFromApi(webSiteId, this.schema.schemaId)
          //apiCall({ url: "Entities/empty?webSiteId="+webSiteId+"&schemaId="+this.schema.schemaId, data: {}, method: "GET" })
          .then((resp) => {
            this.editedItem = resp;
            this.form_loaded = true;
            this.dialog = true;
          })
          .catch((err) => {});
      });
    },
    onEditItem(item) {
      this.$store.dispatch(BLOCK_UI);
      this.form_loaded = false;
      //console.log("onEditItem", item);
      this.editedIndex = this.entities.indexOf(item);
      this.isInsert = false;

      /*console.log(
        "this.editedIndex",
        this.editedIndex,
        "this.isUpdate",
        this.isUpdate
      );*/
      //this.getDataFromApi().then((data) => {
        this.schema = item.schema;//data.schema;

        apiCall({ url: "Entities/" + item.entityId, data: {}, method: "GET" })
          .then(resp => {
            this.entities[this.editedIndex] = resp;

            item = this.entities[this.editedIndex];

            item.versions[0]["contentDecoded"] =
              JSON.parse(item.versions[0].content) || this.schemaConfiguration.model;
            item.versions[0].contents.forEach(content => {
              content["contentDecoded"] = JSON.parse(content.content) || {};
              //console.log(content);
            });

            this.editedItem = item;
            this.form_loaded = true;
            this.dialog = true;
            this.$store.dispatch(UN_BLOCK_UI);
          })
          .catch((err) => { 
            console.error(err);
            this.$store.dispatch(UN_BLOCK_UI);
          });
      //});
    },
    selectFromList() {
      this.selectFromListDialog = true;
    },
    onDialogClosed() {
      this.dialog = false;
      this.showAssetsMassiveUploadsDialog = false;
      this.$nextTick(() => {
        //this.editedItem = Object.assign({}, this.defaultItem);
        //this.editedIndex = -1;
      });
    },

    onSaved() {
      let entityList = Object.assign([], this.internalValue);


      /*if (this.quantity > 1) {
        entityList = Object.assign([], this.internalValue);
      }*/
      if (this.isInsert) {
        /*console.log(
          "entityList.length",
          entityList.length,
          "entityList",
          entityList,
          "this.quantity",
          this.quantity
        );*/
        if (entityList.length < this.quantity) {
          entityList.push({
            entityType: this.entityType,
            schemaKey: this.selectedSchemaKey,
            relationshipType: this.relationshipType,
            selectorRenderer: this.selectorRenderer,
            exportRelationshipToDb: this.exportRelationshipToDb,
            entityId: this.editedItem.entityId,
          });
        } else {
          alert("Limite associazione raggiunto");
        }
      }
      this.update(entityList, "change");
      this.onDialogClosed();
      this.$refs.myProvider.validate(this.internalValue);
    },
    deleteFile(item, i) {
      let entityList = Object.assign([], this.internalValue);
      entityList.splice(i, 1);

      this.update(entityList, "change");

      this.$refs.myProvider.validate(this.internalValue);
    },
    moveItemUp(item, i) {
      let entityList = Object.assign([], this.internalValue);
      
      entityList = this.swap_items(entityList,i,i - 1);

      this.update(entityList, "change");

      this.$refs.myProvider.validate(this.internalValue);
    },
    moveItemDown(item, i) {
      let entityList = Object.assign([], this.internalValue);
      
      entityList = this.swap_items(entityList,i,i + 1);
      
      this.update(entityList, "change");

      this.$refs.myProvider.validate(this.internalValue);
    },
    swap_items(arr, old_index, new_index) {
        // To do: mettere controlli indici array
        
        var el = arr[new_index];
        arr[new_index] = arr[old_index];
        arr[old_index] = el;
        
        return arr; 
    },  
    getRenderingComponentName(item) {
      var sr =  item.selectorRenderer || this.selectorRenderer || "";
      var componetName =
        "cmease-entity-selector-field-renderer-" +
        sr.toLowerCase();
        //console.log("1 Vue.options.components",Vue.options.components,componetName);
      if (!Vue.options.components[componetName]) {
        componetName =
          "cmease-entity-selector-field-renderer-" +
          item.schemaKey?.toLowerCase();

        //console.log("2  Vue.options.components",Vue.options.components,componetName);
     
        if (!Vue.options.components[componetName]) {
          componetName = "cmease-entity-selector-field-renderer-generic";

          //console.log("3 Vue.options.components",Vue.options.components,componetName);
     
        }
      }

      return componetName;
    },
    closeSelectFromListDialog() {
      this.showAssetsMassiveUploadsDialog = false;
      this.selectFromListDialog = false;
    },
    onSelect(selectedEntities) {
      //console.log("selectedEntities", selectedEntities);
      //Aggingere salvataggio
      let entityList = Object.assign([], this.internalValue);
      selectedEntities.forEach((element) => {
        //console.log(element);
        //Verificao se l'elenco contiene già questo elemento
        if(!_.some(entityList, ['entityId', element.entityId])){
          //controllo se l'associazione contiene già abbastanza elementi
          if (entityList.length < this.quantity) {
            entityList.push({
              entityType: this.entityType,
              schemaKey: this.selectedSchemaKey,
              relationshipType: this.relationshipType,
              selectorRenderer: this.selectorRenderer,
              exportRelationshipToDb: this.exportRelationshipToDb,
              entityId: element.entityId,
            });
          }
        }
      });

      this.update(entityList, "change");

      this.$refs.myProvider.validate(this.internalValue);

      this.closeSelectFromListDialog();
    },
    massiveUpload() {     
      //console.log("massiveUpload availableSchema",this.availableSchema,this.selectedSchemaKey)
      for (let index = 0; index < this.availableSchema.length; index++) {
          const schema = this.availableSchema[index];
           //console.log("massiveUpload ciclo for schema",schema);
          if(schema.schemaKey.toLowerCase() == this.selectedSchemaKey.toLowerCase())
          {
            //console.log("schema trovato",schema,this);
            this.schema = schema;
          }
          
      }

      //console.log("massiveUpload schema",this.schema);
      this.showAssetsMassiveUploadsDialog = true;
    }
  },
};
</script>