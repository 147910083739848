<template>
    <v-skeleton-loader :loading="loading" :transition="transition" :tile="true" type="card">
        <v-list-item-title>
            <v-btn icon fab small dark @click="editItem">
              <v-icon color="green lighten-1">edit</v-icon>
            </v-btn>
        
            {{description}}

        </v-list-item-title>        
    </v-skeleton-loader>
</template>
<script>
import { baseApiUrl,apiCall } from "../../utils/api";
import CmeaSeEntitySelectorFieldRendererMixin from './cmease-entity-selector-field-renderer-mixin'
export default {
    mixins: [CmeaSeEntitySelectorFieldRendererMixin],    
    name: 'cmease-entity-selector-field-renderer-menu-item',
    computed: {
        description: function(){            
            return this.entity?.contentDecoded?.entityName;
        }
    },
    methods: {
        editItem() {
            //console.log('edit-item',this.entity);
            this.$emit('edit-item',this.entity);
        } 
    }
}
</script>
   
