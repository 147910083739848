<template>
    <v-skeleton-loader :loading="loading" :transition="transition" :tile="true" type="card">
        <v-list-item-title>
            <v-row>
                <v-col cols="2">
                    <v-card class="pa-5">
             <v-img :lazy-src="imgUrl" height="75" src="imgUrl"  contain  aspect-ratio="1" >   
        
              
            </v-img>
            <v-card-actions>
                {{description}}
              <v-spacer></v-spacer>

              <v-btn icon @click="editItem">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>              
            </v-card-actions>
          </v-card>



                    
                </v-col>
                
            </v-row>
               
        </v-list-item-title> 
        
    </v-skeleton-loader>
</template>
<script>
import { baseApiUrl,apiCall } from "../../utils/api";
import CmeaSeEntitySelectorFieldRendererMixin from './cmease-entity-selector-field-renderer-mixin'
export default {
    mixins: [CmeaSeEntitySelectorFieldRendererMixin],    
    name: 'cmease-entity-selector-field-renderer-img',
    computed: {
        imgUrl: function(){
            //console.log("baseApiUrl",baseApiUrl);
            return baseApiUrl+'WebSites/downloadFile/'+this.currentWebSite+'?filename='+this.entity?.contentDecoded?.file?.fileName;
        },        
        description: function(){            
            return this.entity?.entityName;
        }    
    },
    methods: {
        editItem() {
            //console.log('edit-item',this.entity);
            this.$emit('edit-item',this.entity);
        } 
    }
}
</script>
   
