<template>

      <v-menu
        :close-on-content-click="true"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
          <template v-slot:activator="{ on, attrs }">
              <ValidationProvider v-slot="{ errors }" :name="name" :rules="roules">
                <v-text-field
                  v-model="internalValue"
                  :label="name"
                  prepend-icon="mdi-calendar-edit"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :required="required"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
          </template>

          <v-date-picker 
              v-model="date" 
              :name="name" 
              @input="onInput" 
              locale="it"
          ></v-date-picker>

      </v-menu>

</template> 

<script>
  import CmeaseBaseFieldMixin from './cmease-base-field-mixin'

  export default {    
      mixins: [CmeaseBaseFieldMixin],    
      name: 'field-cmease-datepicker-field',
      data: () => ({
        date: new Date().toISOString().substr(0, 10)
      })
  }
</script>