<template>
<ValidationProvider v-slot="{ errors }" :name="name" :rules="validationRules">
    <v-select
            v-model="internalValue"
            :items="items"
            :label="name"
            :required="required"
            :error-messages="errors"
            multiple
            chips
            deletable-chips
            bottom
            auto
            clearable
            @change="onChange($event)"
    ></v-select>
    </ValidationProvider>
</template>

<script>
  import CmeaseBaseFieldMixin from './cmease-base-field-mixin'
    
  export default {
    mixins: [CmeaseBaseFieldMixin], 
    name: 'cmease-select-multi-field',   
    props: ['items'], 
  }
</script>