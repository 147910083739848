<template>
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="validationRules">
    
    <v-textarea
          :value="internalValue"
          :label="name"
          :required="required"          
          @input="onInput($event)"
          :error-messages="errors"
    ></v-textarea>
  </ValidationProvider>
</template>

<script>
  import CmeaseBaseFieldMixin from './cmease-base-field-mixin'

  export default {
    mixins: [CmeaseBaseFieldMixin],  
    name: 'cmease-textarea-field',    
  }
</script>