<template>
    <v-switch
            :label="name"
            v-model="internalValue"
            @blur="onBlur($event)"
            @change="onChange($event)"
            @focus="onFocus($event)"
            @input="onInput($event)"
            :color="color"
    ></v-switch>
</template>

<script>
  import CmeaseBaseFieldMixin from './cmease-base-field-mixin'

  export default {
    mixins: [CmeaseBaseFieldMixin],    
    name: 'field-cmease-switch',
    props: ['color'],
  }
</script>