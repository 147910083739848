import Vue from 'vue'
import Vuex from 'vuex'
import user from "./modules/user";
import auth from "./modules/auth";
import website from "./modules/website";
import application from "./modules/application";

Vue.use(Vuex)

export default new Vuex.Store({
  
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    auth,
    website,
    application
  }
})
