import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import store from "../store";

Vue.use(VueRouter)




  const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/entities/:schemaKey',
    name: 'Entities',    
    component: () => import('../views/Entities.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/resources',
    name: 'Resources',    
    component: () => import('../views/Resources.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/websiteusers',
    name: 'WebSiteUsers',    
    component: () => import('../views/WebSiteUsers.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/schemas',
    name: 'Schemas',    
    component: () => import('../views/Schemas.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/test-generator',
    name: 'TestGenerator',
    
    component: () => import('../views/TestGenerator.vue'),
    meta: { requiresAuth: true }
  }
]

const router = new VueRouter({
  base: process.env.VUE_APP_BASE_URL,
  mode: 'history',
  routes 
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    
    if (!store.getters.isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
