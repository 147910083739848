import { it } from 'vuetify/es5/locale'

export default {
  ...it,

  login: 'Accedi',
  websiteName: 'Nome Sito',
  dashboard: 'Bacheca',
  addNew: 'Aggiungi nuovo',
  description: 'Descrizione',
  createdAt: 'Creato il',
  updatedAt: 'Aggiornato il',
  actions: 'Azioni',
  cancel: 'Annulla',
  save: 'Salva',
  newItem: 'Nuovo elemento',
  editItem: 'Modifica elemento',
  new: 'Nuovo',
  select: 'Seleziona',
  noItemsSelected: 'Nessun elemento selezionato',
  preview: 'Anteprima',
  confirmSelection: 'Conferma selezione',
  search: 'Cerca',
  position: 'Posizione',
  resourcesLocalization: 'Traduzioni',
  resourceKey: 'Chiva Risorsa',
  languageId: 'Codice Lingua',
  resourceSet: 'Set Risorse',
  value: 'Valore',
  name: 'Nome',
  schemaKey: 'Chiave Schema',
  enabled: 'Abilitato',
  editingType: 'Tipo di editing',
  schemaType: 'Tipo di schema',
  schema: 'Schema',
  schemas: 'Schemi',
  username: 'Nome Utente',
  password: 'Password',
  confirmPassword: 'Conferma Password',
  email: 'Email',
  surname: 'Cognome',
  VATCode: 'Partita Iva',
  DNI: 'Codice Fiscale',
  websiteusers: 'Utenti Sito',
  active: 'Attivo',
  showInMenu: 'Visibile',
  massiveUpload: 'Upload Massivo',
  bitrh_date: 'Data di nascita',
  privacy: 'privacy',
  newsletter: 'newsletter',
  nation: 'Nazione',
  region: 'Regione',
  province: 'Provincia',
  expiration_date: 'Data di scadenza',
  export: 'Esporta',
  role: 'Ruolo Utente',
  sendusernotify: "Invia notifica utente",
  notifysend: "Notifica inviata"
}