import { en } from 'vuetify/es5/locale'

export default {
  ...en,

  login: 'Login',
  websiteName: 'Website Name',
  dashboard: 'Dashboard',
  addNew: 'Add new',
  description: 'Description',
  createdAt: 'Created at',
  updatedAt: 'Updated at',
  actions: 'Actions',
  cancel: 'Cancel',
  save: 'Save',
  newItem: 'New item',
  editItem: 'Edit item',
  new: 'New',
  select: 'Select',
  noItemsSelected: 'No items selected',
  preview: 'Preview',
  confirmSelection: 'Confirm selection',
  search: 'Search',
  position: 'Position',
  resourcesLocalization: 'Localization',
  resourceKey: 'Resource Key',
  languageId: 'Language Code',
  resourceSet: 'Resource Set',
  value: 'Value',
  name: 'Nome',
  schemaKey: 'Chiave Schema',
  enabled: 'Abilitato',
  editingType: 'Tipo di editing',
  schemaType: 'Tipo di schema',
  configuration: 'Configurazione',
  configurationLocalization: 'Configurazione Localizazione',
  schema: 'Schema',
  schemas: 'Schemi',
  username: 'Nome Utente',
  password: 'Password',
  confirmPassword: 'Conferma Password',
  email: 'Email',
  surname: 'Cognome',
  VATCode: 'Partita Iva',
  DNI: 'Codice Fiscale',
  websiteusers: 'Utenti Sito',
  active: 'Attivo',  
  showInMenu: 'Visibile',
  massiveUpload: 'Upload Massivo',
  bitrh_date: 'Data di nascita',
  privacy: 'privacy',
  newsletter: 'newsletter',
  nation: 'Nazione',
  region: 'Regione',
  province: 'Provincia',
  expiration_date: 'Data di scadenza',
  export: 'Esporta',
  role: 'Ruolo Utente',
  sendusernotify: "Invia notifica utente",
  notifysend: "Notifica inviata"
}