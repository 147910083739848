<template>
    <v-alert      
      color="primary"
      dark
      dense
      icon="mdi-information-outline"
    >
      {{title}}
    </v-alert>
</template>

<script>
  import CmeaseBaseFieldMixin from './cmease-base-field-mixin'
  export default {
    mixins: [CmeaseBaseFieldMixin],    
    name: 'cmease-form-group-title-field',
    props: ['title'],
  }
</script>