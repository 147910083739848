<template>
  <div>
    <v-skeleton-loader :loading="firstLoad" :transition="transition" :tile="true" type="table">
      <v-data-table
        dense
        v-model="selected"
        :headers="headers"
        :items="entities"
        :options.sync="options"
        :server-items-length="totalEntities"
        :loading="loading"
        item-key="entityId"
        class="elevation-1"
        show-select
        :single-select="singleSelect"
      >
        <template v-slot:[`item.preview`]="{ item }">
           <v-img v-if="GetImagePath(item)" v-bind:src="GetImagePath(item)" height="50" contain  aspect-ratio="1" ></v-img>
           <v-icon v-else>mdi-cancel</v-icon>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{new Date(item.createdAt).toLocaleString()}}</span>
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }"> 
          <span>{{new Date(item.updatedAt).toLocaleString()}}</span>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{schema.name}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @change="getDataFromApiDebounced"
              @input="getDataFromApiDebounced"
            ></v-text-field>
            
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span>You can use % as a wildcard</span>
            </v-tooltip>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        
      </v-data-table>
                     
    </v-skeleton-loader>
    <v-btn small color="secondary" class="mt-4" @click="select">{{ $vuetify.lang.t('$vuetify.confirmSelection') }}</v-btn>
     
  </div>
</template>


<script>
import { baseApiUrl,apiCall } from "../../utils/api";
//import PrettyPrint from "../components/pretty-print";
import CmeaSeEntitySelectorFieldRendererMixin from './cmease-entity-selector-field-renderer-mixin'
import _ from 'lodash';

export default {
  mixins: [CmeaSeEntitySelectorFieldRendererMixin],  
  name: "Entities",
  props: {
    schemaKey: {
      type: String,
      default: ""
    },
    singleSelect: {
      type: Boolean,
      default: true
    },
    quantity: {
      type: Number,
      default: 1
    }
  },
  components: {
    
  },
  data() {
    return {
      tab: null,
      selected: [],
      blockModal: false,
      dialog: false,
      totalEntities: 0,
      schema: {},
      entities: [],
      firstLoad: true,
      loading: true,
      form_loaded: false,
      transition: "scale-transition",
      options: {
        page : 1,
        itemsPerPage : 10,
        sortBy: ["createdAt"],
         sortDesc: [true]
      },
      editedItem: {},
      defaultItem: {
        contents: []
      },
      editedIndex: -1,
      headers: [
         {
          text: this.$vuetify.lang.t('$vuetify.preview'),
          align: "start",
          sortable: false,
          value: "preview"
        },
        {
          text: this.$vuetify.lang.t('$vuetify.description'),
          align: "start",
          sortable: false,
          value: "entityName"
        },
        {
          text: this.$vuetify.lang.t('$vuetify.createdAt'),
          align: "start",
          sortable: false,
          value: "createdAt"
        },
        {
          text: this.$vuetify.lang.t('$vuetify.updatedAt'),
          align: "start",
          sortable: false,
          value: "updatedAt"
        }
      ],
      state: {},
      isInsert: false,
      search: ''
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApiDebounced();
      },
      deep: true,
      immediate: false
    }
  },
  computed: {
    // a computed getter
    schemaConfiguration: function() {
      // `this` points to the vm instance
      return JSON.parse(this.schema.configuration);
    },
    schemaLocalizationConfiguration: function() {
      // `this` points to the vm instance
      return JSON.parse(this.schema.configurationLocalization);
    },
    currentWebSite() {
      return this.$store.getters.getCurrentWebSite;
    }
		
  },
  mounted() {
    this.getDataFromApiDebounced();
  },  
  methods: {
    onChangeState(value) {
      this.state = value;
    },
    getDataFromApi() {
      this.loading = true;

      return new Promise((resolve, reject) => {
        apiCall({
          url: "Schema/byKey/" + this.schemaKey,
          data: {},
          method: "GET"
        })
          .then(resp => {
            this.schema = resp;

            this.defaultItem.contents = [];
            this.defaultItem.contents.push({
              LanguageId: "it",
              Content: resp.model
            });

            this.firstLoad = false;
            /*console.log(
              "schema key",
              this.schemaKey,
              "schema",
              this.schema,
              "configuration",
              this.schemaConfiguration
            );*/
            const {
                  sortBy, sortDesc,
                  page,
                  itemsPerPage
                } = this.options;

            let filters = {
              WebSiteId: this.currentWebSite,
              SchemaId: this.schema.schemaId,
              Page: page,
              Limit: itemsPerPage,
              SortBy: Array.isArray(sortBy) ? sortBy : [...sortBy],
              SortDesc: Array.isArray(sortDesc) ? sortDesc : [...sortDesc],
              GenericSearch: '%'+this.search+'%'
            };
            apiCall({ url: "Entities/paged", data: filters, method: "GET" })
              .then(resp => {
                let items = resp.results;
                const total = resp.rowCount;

                /*if (itemsPerPage > 0) {
                  items = items.slice(
                    (page - 1) * itemsPerPage,
                    page * itemsPerPage
                  );
                }*/

                items.forEach(entity => {
                  entity.versions[0]["contentDecoded"] = JSON.parse(entity.versions[0].content) || {};

                  entity.versions[0].contents.forEach(content => {
                    content["contentDecoded"] =
                      JSON.parse(content.content) || {};
                  });
                });

                //console.log("items", items);

                this.loading = false;
                resolve({
                  items,
                  total
                });
              })
              .catch(err => {
                reject(err);
              });
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getEmptyDataFromApi(webSiteId, schemaId) {
      return new Promise((resolve, reject) => {
        apiCall({
          url:
            "Entities/empty?webSiteId=" + webSiteId + "&schemaId=" + schemaId,
          data: {},
          method: "GET"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },       
    select(){
      //Aggiungere controllo numero elementi selezionati
      this.$emit('select',this.selected);
    },
    getDataFromApiDebounced: _.debounce(function() {
        this.getDataFromApi().then(data => {
          this.entities = data.items;
          this.totalEntities = data.total;
        }).catch(e => {
          console.error(e);
        });   
    }, 1000),
    GetImagePath(entity)
    {
      try{
        //console.log(entity);
        return baseApiUrl+'WebSites/downloadFile/'+this.currentWebSite+'?filename='+entity.versions[0].contentDecoded.file.fileName;
      }
      catch(e)
      {
        console.error(e);
        return false;
      }
    }
          
    
  }
};
</script>