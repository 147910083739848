var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"4"}},[_c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-spacer'),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.login')))]),_c('v-spacer')],1),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.login)}}},[_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Mail","name":"login","type":"text","error-messages":errors,"required":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}},[_c('v-icon',{attrs:{"slot":"prepend","color":"primary"},slot:"prepend"},[_vm._v("mdi-account")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"password","label":"Password","name":"password","type":"password","error-messages":errors,"required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}},[_c('v-icon',{attrs:{"slot":"prepend","color":"primary"},slot:"prepend"},[_vm._v("mdi-lock")])],1)]}}],null,true)})],1),_c('v-card-actions',{staticClass:"justify-center pb-8"},[_c('v-btn',{attrs:{"type":"submit","color":"primary","x-large":"","disabled":invalid}},[_vm._v("Login")])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }