<template>
   <v-text-field
      :value="internalValue"
      :label="name"
    v-if="false"
      
    
    ></v-text-field>
</template>

<script>
import CmeaseBaseFieldMixin from "./cmease-base-field-mixin";
import { baseApiUrl,apiCall } from "../../utils/api";
export default {
  mixins: [CmeaseBaseFieldMixin],
  name: "field-cmease-dataextractor-field",
  props: {
    inputSource: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
        values: {}
      }),
  computed:{
   currentWebSite() {
      return this.$store.getters.getCurrentWebSiteObj;
    }
    },
  mounted() {
    this.fieldExtractor();
    this.$root.$on("objectUpdated", this.fieldExtractor);
  },
  methods: {
    fieldExtractor: function () {
      
      this.internalValue ="";
      for (var i = 0; i < this.inputSource.length; i++) {
        var iE = this.inputSource[i];
         iE.index = i.toString();
        switch (iE.type) {
           case "configuration":
           if(this.currentWebSite.Configurations && this.currentWebSite.Configurations[iE.fieldName]){
                this.values[iE.index] =this.currentWebSite.Configurations[iE.fieldName];
  
           }
            break;
          case "internal":
           this.values[iE.index] =
              this.editedItem.versions[0]["contentDecoded"][iE.fieldName];
             
            break;
            case "linkedResource":
             var v = this.editedItem.versions[0]["contentDecoded"][iE.fieldName]
          
             if(v.length>0){
                var entityId = v[0].entityId;
             this.setValueFromApi(this,entityId, iE.index, iE.linkedResourceFieldName)
             
             }
           
              break;
        }
         this.setValue(); 
      }
    },
    setValueFromApi(vm,entityId,index,fieldname){
 apiCall({ url: "Entities/" + entityId, data: {}, method: "GET" })
                      .then(resp => {
                        resp.versions[0]["contentDecoded"] = JSON.parse(resp.versions[0].content) || {};
            
                          
                        vm.values[index] = resp.versions[0]["contentDecoded"][fieldname];
                        vm.setValue();
                      })
                      .catch((err) => { 
                        console.error(err);
                      
                      });

    },
    setValue(){
      var arr = [];
      for(var i in this.values)
      {
        arr.push(this.values[i]);
      }
this.internalValue = arr.join("/");
this.update(this.internalValue , "change");
    }
  },
};
</script>