import { baseApiUrl,apiCall } from "../../utils/api";

const CmeaSeEntitySelectorFieldRendererMixin = {    
    props: ['entityId','schemaKey','entityType','relationshipType','selectorRenderer','exportToDb'],
    data()
    {
        return {
            loading : false,
            entity: {},
            transition: "scale-transition",
        }
    },
    computed: {        
        currentWebSite() {
          return this.$store.getters.getCurrentWebSite;
        }
      },
      mounted() {
        this.getEntity().then(resp => {
            this.entity = resp;

            

            this.entity["contentDecoded"] =  JSON.parse(this.entity.versions[0].content) || {};
            this.entity.versions[0].contents.forEach(content => {
                content["contentDecoded"] = JSON.parse(content.content) || {};
                
            });

            
            this.loading = false;

            //console.log("resp",resp,"this.entity",this.entity);
        })
        .catch(err => {
            console.error('cmease-entity-selector-field-renderer getEntity error',err);

        });
      },
    methods: {
        getEntity() {
            this.loading = true;

            return new Promise((resolve, reject) => {
            
                apiCall({ url: "Entities/" + this.entityId, data: {}, method: "GET" })
                .then(resp => {
                    resolve(resp);
                  })
                  .catch(err => {
                    reject(err);
                  });
                

            });
          },
          GetImagePath(content) {
         
            if (content && content.file && content.file.originalFileName) {
              // console.log('content type: ' + content.file.contentType);
              var file_type = content.file.contentType;

              if (file_type.includes("image/")) {
                return baseApiUrl + 'WebSites/downloadFile/' + this.currentWebSite + '?filename=' + content.file.fileName;
              }
              if (file_type.includes('pdf')) {
                return require('../../assets/FileIcon/ico_pdf.png');
              }
              else {
                return require('../../assets/FileIcon/ico_generic.png')
              }

            }
          },
          GetFileName(content) {

            if (content && content.file && content.file.originalFileName) {
              return content.file.originalFileName;
            }

          }
        
    }
}

export default CmeaSeEntitySelectorFieldRendererMixin