<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-spacer></v-spacer>
            <v-toolbar-title>{{ $vuetify.lang.t('$vuetify.login') }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <ValidationObserver ref="observer" v-slot="{ handleSubmit,invalid }">
            <form @submit.prevent="handleSubmit(login)">
              <v-card-text>
                <ValidationProvider v-slot="{ errors }" name="Name" rules="required">
                  <v-text-field
                    label="Mail"
                    name="login"
                    type="text"
                    v-model="username"
                    :error-messages="errors"
                    required
                  >
                    <v-icon slot="prepend" color="primary">mdi-account</v-icon>
                  </v-text-field>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" name="Name" rules="required">
                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    v-model="password"
                    :error-messages="errors"
                    required
                  >
                    <v-icon slot="prepend" color="primary">mdi-lock</v-icon>
                  </v-text-field>
                </ValidationProvider>
              </v-card-text>
              <v-card-actions class="justify-center pb-8">
                <v-btn type="submit" color="primary" x-large :disabled="invalid">Login</v-btn>
              </v-card-actions>
            </form>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AUTH_REQUEST } from "../store/actions/auth";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    login: function () {
      const { username, password } = this;

      //.log({ username, password });
      this.$store.dispatch(AUTH_REQUEST, { username, password }).then(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>