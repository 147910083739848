<template>
<ValidationProvider v-slot="{ errors }" :name="name" :rules="validationRules">
   
    <v-select
            v-model="internalValue.selected"
            :items="items"
            :label="name"
            :required="required"
            :error-messages="errors"
            multiple
            chips
            deletable-chips
            bottom
            auto
            clearable
            @change="onChange($event,'selected')"
    ></v-select>

    <v-text-field
          :value="internalValue.text"
          :label="name"
          :required="required"
          @input="onInput($event,'text')"
          
          :error-messages="errors"
      ></v-text-field>
    </ValidationProvider>
</template>

<script>
  import CmeaseBaseFieldMixin from './cmease-base-field-mixin'
  
  export default {
    name: 'cmease-complex-field-example',
    mixins: [CmeaseBaseFieldMixin],
    props: {
      items: {
        type: Array,
        default: () => []
      },
      value: {
        type: Object,
        default: () =>  {
          return {
            selected: [],
          text: ""
          }
        }
      },
    }
  }
</script>