<template>
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="validationRules">
    <v-radio-group 
      v-model="internalValue"
      :error-messages="errors"
      :label="name"
      @change="onChange($event)"
      :disabled="disabled"
    >
      <v-radio
        v-for="(item, key) in items"
        :key="key"
        :label="item.text"
        :value="item.value"
      ></v-radio>
    </v-radio-group>  
  </ValidationProvider>  
</template>

<script>
  import CmeaseBaseFieldMixin from './cmease-base-field-mixin'

  export default {
    mixins: [CmeaseBaseFieldMixin],    
    name: 'cmease-radio-group-field',   
    props: ['items'], 
  }
</script>