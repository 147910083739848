import {
    BLOCK_UI,
    UN_BLOCK_UI
} from "../actions/application";

const state = { 
    blockUI : false
};

const getters = {
    getblockUI: state => state.blockUI,
};

const actions = {
    [BLOCK_UI]: ({ commit }) => {        
        commit(BLOCK_UI);
    },
    [UN_BLOCK_UI]: ({ commit }) => {
        commit(UN_BLOCK_UI);
    }
}

const mutations = {
    [BLOCK_UI]: state => {        
        state.blockUI = true;
    },
    [UN_BLOCK_UI]: state => {
        state.blockUI = false;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};
  