import Vue from 'vue'

import VueFormJsonSchema from 'vue-form-json-schema';
Vue.component('vue-form-json-schema', VueFormJsonSchema);

import FormGeneratorWrapper from '../components/FormGeneratorWrapper'
Vue.component('FormGeneratorWrapper', FormGeneratorWrapper)


// Import fields
import CmeaSeTextField from '../components/fields/cmease-text-field'
import CmeaSeSlugTextField from '../components/fields/cmease-slug-text-field'
import CmeaSeCheckboxField from '../components/fields/cmease-checkbox-field'
import CmeaSeRadioGroupField from '../components/fields/cmease-radio-group-field'
import CmeaSeSwitchField from '../components/fields/cmease-switch-field'
import CmeaSeTextareaField from '../components/fields/cmease-textarea-field'
import CmeaSeSelectField from '../components/fields/cmease-select-field'
import CmeaSeSelectMultiField from '../components/fields/cmease-select-multi-field'
import CmeaSeComplexExampleField from '../components/fields/cmease-complex-field-example'
import CmeaSeFileUploadField from '../components/fields/cmease-file-upload-field'
import CmeaSeEntitySelectorField from '../components/fields/cmease-entity-selector-field'
import CmeaSeEntitySelectorFieldRendererImg from '../components/fields/cmease-entity-selector-field-renderer-img'
import CmeaSeEntitySelectorFieldRendererDoc from '../components/fields/cmease-entity-selector-field-renderer-doc'
import CmeaSeEntitySelectorFieldRendererGeneric from '../components/fields/cmease-entity-selector-field-renderer-generic'
import CmeaSeEntitySelectorFieldRendererMenuItem from '../components/fields/cmease-entity-selector-field-renderer-menu-item'
import CmeaSeEntitySelectorFieldEntitiesList from '../components/fields/cmease-entity-selector-field-entities-list'
import CmeaSeHtmlEditorField from '../components/fields/cmease-html-editor-field'
import CmeaSeGeolocationField from '../components/fields/cmease-geolocation-field'
import CmeaSeDatePickerField from '../components/fields/cmease-datepicker-field'
import CmeaSeFormGroupTitleField from '../components/fields/cmease-form-group-title-field'
import CmeaSeDataExtractorField from '../components/fields/cmease-dataextractor-field'
import CmeaSeQrCodeField from '../components/fields/cmease-qrcode-field'
import CmeaSeColorField from '../components/fields/cmease-color-field'



Vue.component('cmease-text-field', CmeaSeTextField)
Vue.component('cmease-slug-text-field', CmeaSeSlugTextField)
Vue.component('cmease-checkbox-field', CmeaSeCheckboxField)
Vue.component('cmease-radio-group-field', CmeaSeRadioGroupField)
Vue.component('cmease-switch-field', CmeaSeSwitchField)
Vue.component('cmease-textarea-field', CmeaSeTextareaField)
Vue.component('cmease-select-field', CmeaSeSelectField)
Vue.component('cmease-select-multi-field', CmeaSeSelectMultiField)
Vue.component('cmease-complex-example-field', CmeaSeComplexExampleField)
Vue.component('cmease-file-upload-field', CmeaSeFileUploadField)
Vue.component('cmease-entity-selector-field', CmeaSeEntitySelectorField)
Vue.component('cmease-entity-selector-field-renderer-img', CmeaSeEntitySelectorFieldRendererImg)
Vue.component('cmease-entity-selector-field-renderer-doc', CmeaSeEntitySelectorFieldRendererDoc)
Vue.component('cmease-entity-selector-field-renderer-generic', CmeaSeEntitySelectorFieldRendererGeneric)
Vue.component('cmease-entity-selector-field-renderer-menu-item', CmeaSeEntitySelectorFieldRendererMenuItem)
Vue.component('cmease-entity-selector-field-entities-list', CmeaSeEntitySelectorFieldEntitiesList)
Vue.component('cmease-html-editor-field', CmeaSeHtmlEditorField)
Vue.component('cmease-geolocation-field', CmeaSeGeolocationField)
Vue.component('cmease-datepicker-field', CmeaSeDatePickerField)
Vue.component('cmease-form-group-title-field', CmeaSeFormGroupTitleField)
Vue.component('cmease-dataextractor-field', CmeaSeDataExtractorField)
Vue.component('cmease-qrcode-field', CmeaSeQrCodeField)
Vue.component('cmease-color-field', CmeaSeColorField)