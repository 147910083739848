<template>
    <div class="text-center">   
        <v-img
          :src="GetImagePath(entity.versions[0].contentDecoded)"
          class="my-3"
          contain
          height="200"
        />

        <v-text-field
            :value="GetFileName(entity.versions[0].contentDecoded)"
            :readonly="true"
            :contenteditable="false"
        ></v-text-field>

    </div>
</template>
<script>
import { baseApiUrl,apiCall } from "../../utils/api";
import CmeaSeEntitySelectorFieldRendererMixin from './cmease-entity-selector-field-renderer-mixin'

export default {
    mixins: [CmeaSeEntitySelectorFieldRendererMixin],    
    name: 'cmease-entity-selector-field-renderer-doc'
}
</script>
   
