<template>
  <div>
    
    <v-card flat>
      <v-toolbar flat dark color="primary">
        <v-container fluid>
          <v-row align="center" justify="space-between">
            <v-col>
              <v-toolbar-title>{{ $vuetify.lang.t('$vuetify.massiveUpload')}} </v-toolbar-title>
            </v-col>
            <v-col class="text-right">
              <v-btn icon dark @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar>

      <v-container>
         <v-btn small color="secondary" class="mb-4" @click="select" v-if="showConfirmSelectionBtn">{{ $vuetify.lang.t('$vuetify.confirmSelection') }}</v-btn> 
         <dashboard :uppy="uppy"  :props="{theme: 'light'}" />
         <v-btn small color="secondary" class="mt-4" @click="select" v-if="showConfirmSelectionBtn">{{ $vuetify.lang.t('$vuetify.confirmSelection') }}</v-btn>
    
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { baseApiUrl,apiCall, uploadFile } from "../utils/api";
import { getFlagFromLangCode } from "../utils/flags";
import PrettyPrint from "../components/pretty-print";
import { BLOCK_UI, UN_BLOCK_UI  } from '../store/actions/application';
import slugify from 'slugify'
import Uppy from '@uppy/core'
import { Dashboard } from '@uppy/vue'
const XHRUpload = require('@uppy/xhr-upload')

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'




export default {
  name: "AssetsMassiveUploads",
  props: {    
    value: {
      type: Array,
      default() {
        return [];
      }
    },
    schema: {},
    showConfirmSelectionBtn: {
      type: Boolean,
      default: false
    },
  },
  components: {
   Dashboard
  },
  data() {
    return {
     
      form_loaded: true,
      uploadedEntities: this.value
      
    };
  },
  watch: {
    
  },  
  computed: {
    uppy: function () {
      console.log(this,XHRUpload);
        let cw = this.currentWebSite;
        return new Uppy({
        debug: true,
        autoProceed: true
        }).use(XHRUpload, {
          endpoint: baseApiUrl+"WebSites/uploadFile/" + cw,
          fieldName: "uploadedFile",
          FormData: true,
          limit: 10,
          headers: {
            'authorization': `Bearer ${localStorage.getItem("user-token")}`
          }
        }).on('upload-success', (file, response) => {
          //console.log("upload response",response);

          this.insertAsset(response);
        });
    },
    isUpdate: function () {
      return !this.isInsert;
    },
    webSites() {
      return this.$store.getters.getWebSites;
    },
    currentWebSite() {
      return this.$store.getters.getCurrentWebSite;
    },
    schemaConfiguration: function () {
      // `this` points to the vm instance
      return JSON.parse(this.schema.configuration);
    },
    schemaLocalizationConfiguration: function () {
      // `this` points to the vm instance
      return JSON.parse(this.schema.configurationLocalization);
    },
    schemaKey: function () {
      return this.schema.schemaKey;
    },
    isDebug: function () {
      return process.env.NODE_ENV !== "production";
    }
  },
  mounted() {
  },
  beforeUpdate() {},
  beforeDestroy () {
    this.uppy.close()
  },
  methods: {    
    close() {
      /*this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });*/
      this.$emit("closed");
    },
    getEmptyDataFromApi(webSiteId, schemaId) {
      return new Promise((resolve, reject) => {
        apiCall({
          url:
            "Entities/empty?webSiteId=" + webSiteId + "&schemaId=" + schemaId,
          data: {},
          method: "GET"
        })
          .then(resp => {
            resolve(resp);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    insertAsset(response){
      if(response.status === 200)
      {
        let uploadedFile = response.body;
        let webSiteId = this.$store.getters.getCurrentWebSite;
        this.getEmptyDataFromApi(webSiteId, this.schema.schemaId)
          //apiCall({ url: "Entities/empty?webSiteId="+webSiteId+"&schemaId="+this.schema.schemaId, data: {}, method: "GET" })
          .then(resp => {
            //console.log("insertAsset resp",resp,"schemaConfiguration",this.schemaConfiguration,"schemaLocalizationConfiguration",this.schemaLocalizationConfiguration);
            var newItem = resp;

            var extension = uploadedFile.fileName.split('.').pop();
            var entityName = uploadedFile.originalFileName.replace("."+extension,"");

            var slug = slugify(entityName).toLowerCase();

            newItem.entityName = entityName;

            //console.log("slug",slug);

            var content = {};

            for (let index = 0; index < this.schemaConfiguration.uiSchema.length; index++) {
              let element = this.schemaConfiguration.uiSchema[index];
              let propertyName = element.model;
              let component = element.component;

              if(component === "cmease-text-field")
              {
                content[propertyName] = entityName;
              }
              else if(component === "cmease-slug-text-field")
              {
                content[propertyName] = slug;
              }
              else if(component === "cmease-file-upload-field")
              {
                content[propertyName] = Object.assign(uploadedFile, {"extension" : extension});
              }
              
            }

            var contentLocalized = {};

            for (let index = 0; index < this.schemaLocalizationConfiguration.uiSchema.length; index++) {
              let element = this.schemaLocalizationConfiguration.uiSchema[index];
              let propertyName = element.model;
              let component = element.component;

              if(component === "cmease-text-field")
              {
                contentLocalized[propertyName] = entityName;
              }
              else if(component === "cmease-slug-text-field")
              {
                contentLocalized[propertyName] = slug;
              }
              else if(component === "cmease-file-upload-field")
              {
                contentLocalized[propertyName] = Object.assign(uploadedFile, {"extension" : extension});
              }
              
            }

            this.CopyMatchingProperty(
              newItem,
              content
            );

            newItem.versions?.forEach((version) => {
              this.CopyMatchingProperty(version, content);
              version["content"] = JSON.stringify(content,null,0);

              version.contents?.forEach((locContent) => {
                this.CopyMatchingProperty(locContent, contentLocalized);
                locContent["content"] = JSON.stringify(contentLocalized,null,0);
              });
            });

            /*for (let index = 0; index < newItem.versions.length; index++) {
              newItem.versions[index]["content"] = JSON.stringify(content,null,0);
              newItem.versions[index]["slug"] = slug;
              newItem.versions[index]["entityName"] = entityName;

              for (let cindex = 0; cindex < newItem.versions[index].contents.length; cindex++) {
                newItem.versions[index].contents[cindex]["content"] = JSON.stringify(contentLocalized,null,0);
                newItem.versions[index].contents[cindex]["slug"] = slug;
                newItem.versions[index].contents[cindex]["entityName"] = entityName;
              }
            }*/

            //console.log("newItem completato",newItem);


            apiCall({
              url: "Entities/",
              data: newItem,
              method: "POST",
            })
              .then((resp) => {
                this.uploadedEntities.push(resp);
                //console.log("asset creato",resp);
              })
              .catch((err) => {
                console.error("errore di creazione",err);
              });

          })
          .catch(err => {console.error(err);});
      }
      else
      {
        //todo: gestire questo caso
      }
    },
    CopyMatchingProperty(Target, Source) {
      //console.log(Target, Source);
      return Object.keys(Target).forEach((key) => {
        if (Source !== undefined && Source[key] !== undefined)
          Target[key] = Source[key];
      });
    },       
    select(){
      //Aggiungere controllo numero elementi selezionati
      this.$emit('select',this.uploadedEntities);
    },
    
  }
};
</script>