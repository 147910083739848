<template>
  <div>
    
    <v-card flat>
      <v-toolbar flat dark color="primary">
        <v-container fluid>
          <v-row align="center" justify="space-between">
            <v-col>
              <v-toolbar-title>{{ isInsert ? $vuetify.lang.t('$vuetify.newItem') : $vuetify.lang.t('$vuetify.editItem')}}</v-toolbar-title>
            </v-col>
            <v-col class="text-right">
              <v-btn icon dark @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar>

      <v-container>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit/*, invalid,errors*/ }">
          <form @submit.prevent="handleSubmit(save)" v-if="form_loaded" novalidate>
            <v-card-actions>
              <v-btn text color="secondary" @click="close">
                <v-icon left>mdi-backspace-outline</v-icon>
                {{ $vuetify.lang.t('$vuetify.cancel') }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="secondary" type="submit">
                <v-icon left>mdi-content-save</v-icon>
                {{ $vuetify.lang.t('$vuetify.save') }}
              </v-btn>
            </v-card-actions>

            <v-card-text>
              <FormGeneratorWrapper
                v-model="editedItem.versions[0].contentDecoded"
                :schema="schemaConfiguration.schema"
                :ui-schema="schemaConfiguration.uiSchema"
                @change="onChange"
                v-on:state-change="onChangeState"
              ></FormGeneratorWrapper>

              <v-card>
                <v-tabs dark background-color="primary" v-model="tab" v-if="form_loaded">
                  <v-tab
                    v-for="item in editedItem.versions[0].contents"
                    :key="item.webSiteLanguage.languageId"
                  ><country-flag :country='getFlagFromLangCode(item.webSiteLanguage.languageId)' size='normal'/><span class="ml-2">{{ item.webSiteLanguage.languageId }}</span></v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" v-if="form_loaded">
                  <v-tab-item
                    v-for="item in editedItem.versions[0].contents"
                    :key="item.webSiteLanguage.languageId"
                  >
                    <v-card flat>
                      <v-card-text>
                        <FormGeneratorWrapper
                          v-model="item.contentDecoded"
                          :schema="schemaLocalizationConfiguration.schema"
                          :ui-schema="schemaLocalizationConfiguration.uiSchema"
                          @change="item.contentDecoded = $event"
                          :language="item.webSiteLanguage"
                        ></FormGeneratorWrapper>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-card-text>

            <v-card-actions class="pt-8">
              <v-btn text color="secondary" @click="close">
                <v-icon left>mdi-backspace-outline</v-icon>
                {{ $vuetify.lang.t('$vuetify.cancel') }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="secondary" type="submit">
                <v-icon left>mdi-content-save</v-icon>
                {{ $vuetify.lang.t('$vuetify.save') }}
              </v-btn>
            </v-card-actions>

            <v-card-text>
              <v-expansion-panels class="py-5" v-if="isDebug">
                <v-expansion-panel key="debug">
                  <v-expansion-panel-header>Debug</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <hr />
                    <h4>Entity Schema</h4>
                    <pretty-print :value="schema" v-if="form_loaded"></pretty-print>

                    <h4>Value</h4>
                    <pretty-print :value="value" v-if="form_loaded"></pretty-print>
                    <h4>Model</h4>
                    <pretty-print :value="editedItem" v-if="form_loaded"></pretty-print>

                    <h4>Schema</h4>
                    <pretty-print :value="schemaConfiguration.schema" v-if="form_loaded"></pretty-print>

                    <h4>UI Schema</h4>
                    <pretty-print :value="schemaConfiguration.uiSchema" v-if="form_loaded"></pretty-print>
                    <h4>Schema Localization</h4>
                    <pretty-print :value="schemaLocalizationConfiguration.schema" v-if="form_loaded"></pretty-print>

                    <h4>UI Schema Localization</h4>
                    <pretty-print
                      :value="schemaLocalizationConfiguration.uiSchema"
                      v-if="form_loaded"
                    ></pretty-print>

                    <h4>State</h4>
                    <pretty-print :value="state" v-if="form_loaded"></pretty-print>
                    <v-spacer />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </form>
        </ValidationObserver>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { apiCall } from "../utils/api";
import { getFlagFromLangCode } from "../utils/flags";
import PrettyPrint from "../components/pretty-print";
import { BLOCK_UI, UN_BLOCK_UI  } from '../store/actions/application';

export default {
  name: "EntityEditForm",
  props: {
    isInsert: {
      type: Boolean,
      required: false,
    },
    value: {},
    schema: {},
  },
  components: {
    "pretty-print": PrettyPrint,
  },
  data() {
    return {
      tab: null,
      blockModal: false,
      dialog: false,
      totalEntities: 0,
      entities: [],
      firstLoad: true,
      loading: true,
      form_loaded: true,
      transition: "scale-transition",
      options: {},
      defaultItem: {
        contents: [],
      },
      state: {},
      editedItem: this.value,
    };
  },
  watch: {
    blockModal : function (newStatus, oldStatus) {
      this.$store.dispatch(newStatus ? BLOCK_UI : UN_BLOCK_UI);
    }
  },
  provide: function () {
    return {
      editedItem: this.editedItem,
      currentWebSite: this.currentWebSite,
      webSites: this.webSites
    };
  },
  computed: {
    isUpdate: function () {
      return !this.isInsert;
    },
    webSites() {
      return this.$store.getters.getWebSites;
    },
    currentWebSite() {
      return this.$store.getters.getCurrentWebSite;
    },
    schemaConfiguration: function () {
      // `this` points to the vm instance
      return JSON.parse(this.schema.configuration);
    },
    schemaLocalizationConfiguration: function () {
      // `this` points to the vm instance
      return JSON.parse(this.schema.configurationLocalization);
    },
    schemaKey: function () {
      return this.schema.schemaKey;
    },
    isDebug: function () {
      return process.env.NODE_ENV !== "production";
    },
  },
  mounted() {},
  beforeUpdate() {},
  methods: {
  
    onChange($event){
      console.log("onChange");
this.editedItem.versions[0].contentDecoded = $event
this.$root.$emit("objectUpdated","");
    },
    onChangeState(value) {
      this.state = value;
    },
    getFlagFromLangCode(langCode)
    {
      return getFlagFromLangCode(langCode);
    },
    close() {
      /*this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });*/
      this.$emit("closed");
    },
    save() {
      //console.log("editedItem", this.editedItem, "IsInsert", this.isInsert);
      this.blockModal = true;

      this.editedItem.versions[0]["content"] = JSON.stringify(
        this.editedItem.versions[0].contentDecoded,null,0
      );
      this.CopyMatchingProperty(
        this.editedItem,
        this.editedItem.versions[0].contentDecoded
      );
      this.CopyMatchingProperty(
        this.editedItem.versions[0],
        this.editedItem.versions[0].contentDecoded
      );
      this.editedItem.versions[0].contents?.forEach((content) => {
        this.CopyMatchingProperty(content, content.contentDecoded);
        content["content"] = JSON.stringify(content.contentDecoded,null,0);
      });
      if(this.editedItem.schema)
      {
        try{
          this.editedItem.schema.configuration = JSON.stringify(this.schemaConfiguration,null,0);
          this.editedItem.schema.configurationLocalization = JSON.stringify(this.schemaLocalizationConfiguration,null,0);
    
        }
        catch(e){
          console.error(e);
        }
      }
      apiCall({
        url: "Entities" + (this.isInsert ? "" : "/" + this.editedItem.entityId),
        data: this.editedItem,
        method: this.isInsert ? "POST" : "PUT",
      })
        .then((resp) => {
          this.blockModal = false;
          this.$emit("saved");
          /*this.getDataFromApi().then(data => {
            this.entities = data.items;
            this.totalEntities = data.total;
            this.close();
            this.blockModal = false;
          });*/
        })
        .catch((err) => {
          this.blockModal = false;
        });
    },
    CopyMatchingProperty(Target, Source) {
      //console.log(Target, Source);
      return Object.keys(Target).forEach((key) => {
        if (Source !== undefined && Source[key] !== undefined)
          Target[key] = Source[key];
      });
    },
  },
};
</script>