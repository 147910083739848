<template>
  <div>
    <a ref="qrCodeA"></a>
    <vue-qrcode :value="value" :width="300" :id="qrcodeid" />
    <p>
      <button
        class="
          v-btn v-btn--flat v-btn--text
          theme--light
          v-size--default
          secondary--text
        "
        v-on:click.stop.prevent="downloadFile"
      >
        Scarica qrcode
      </button>

      <i>{{ value }}</i>
    </p>
  </div>
</template>

<script>
import CmeaseBaseFieldMixin from "./cmease-base-field-mixin";
import VueQrcode from "vue-qrcode";
 import slugify from 'slugify'
export default {
  mixins: [CmeaseBaseFieldMixin],
  name: "field-cmease-qrcode-field",
  components: {
    VueQrcode,
  },
  props: {
    editable: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    values: {},
    qrcodeid: "qrcodecontainer" + new Date().getTime(),
  }),
  computed: {},
  mounted() {
    //this.$root.$on("objectUpdated", this.fieldExtractor);
  },
  methods: {
    downloadFile() {
      var me = this;
      var blob = this.converBase64toBlob(document.getElementById(this.qrcodeid).src.replace("data:image/png;base64,",""),"image/png");
    
  
      var o=slugify(this.value).toLowerCase()+".png";

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // for IE
            window.navigator.msSaveOrOpenBlob(blob, o);
          } else {
            me.$refs.qrCodeA.href = URL.createObjectURL(blob);
            me.$refs.qrCodeA.download = o ;
            me.$refs.qrCodeA.click();
          }
      
    },
    converBase64toBlob(content, contentType) {
      console.log(content)
      contentType = contentType || "";
      var sliceSize = 512;
      var byteCharacters = window.atob(content); //method which converts base64 to binary
      var byteArrays = [];
      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      var blob = new Blob(byteArrays, {
        type: contentType,
      }); //statement which creates the blob
      return blob;
    },
  },
};
</script>