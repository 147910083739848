<template>
  <v-color-picker
    v-model="internalValue"
    dot-size="25"
    swatches-max-height="200"
    @blur="onBlur($event)"
    @change="onChange($event)"
    @focus="onFocus($event)"
    @input="onInput($event)"
  ></v-color-picker>
</template>

<script>
import CmeaseBaseFieldMixin from "./cmease-base-field-mixin";

export default {
  mixins: [CmeaseBaseFieldMixin],
  name: "field-cmease-color-field",
};
</script>