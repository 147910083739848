import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);


import it from './../i18n/it';
import en from './../i18n/en';

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#1832af',
        secondary: '#337ab7'
      }
    }
  },
  lang: {
    locales: { en, it },
    current: 'en'
  }
});
