<template>
    
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="validationRules">
    <v-row>
        <v-col>
          <label>{{name}}</label>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
   <div id="cmease-html-editor-field">
     <v-editor
       :api-key="APIKEY_TINYMCE"
       :value="internalValue"
       :label="name"
       :required="required"          
       @input="onInput($event)"
       :error-messages="errors"
       tinymceScriptSrc="/tinymce_7.1.0/tinymce/js/tinymce/tinymce.min.js"
       :init="editorConfiguration"
     />
     </div>
     </v-col>
    </v-row>
    <v-dialog
              v-model="fileDialog"              
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              :retain-focus="false"
              style='z-index:20001;'
            >
              
              <v-card flat>
                <v-toolbar flat dark color="primary">
                  <v-container fluid>
                    <v-row align="center" justify="space-between">
                      <v-col>
                        <v-toolbar-title>{{ isInsert ? $vuetify.lang.t('$vuetify.newItem') : $vuetify.lang.t('$vuetify.editItem')}}</v-toolbar-title>
                      </v-col>
                      <v-col class="text-right">
                        <v-btn icon dark @click="fileDialog = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-toolbar>

                <v-container>
                    <v-alert
                      dense
                      outlined
                      type="error"
                      v-if="fileDialog && !selectedSchemaKey"
                    >
                      Configurazine non presente
                    </v-alert>
                    <cmease-entity-selector-field-entities-list
                      :schemaKey="selectedSchemaKey"
                      :single-select="true"
                      v-if="fileDialog && selectedSchemaKey"
                      :quantity="1"
                      @select="onSelect"
                      :isInsert="isInsert"
                    ></cmease-entity-selector-field-entities-list>
                </v-container>
              </v-card>
    </v-dialog>
    </ValidationProvider>

</template>
  <style>
  .tox-promotion{
    display:none !important;
  }
  </style>
<script>
 import CmeaseBaseFieldMixin from './cmease-base-field-mixin'
 import Editor from '@tinymce/tinymce-vue'
 import { baseApiUrl } from "../../utils/api";

 export default {
   mixins: [CmeaseBaseFieldMixin],    
   name: 'cmease-html-editor-field',
   inject: ['currentWebSite','webSites','language'],
   props: {
      showSelectFiles: {
        type: Boolean,
        default: () => false
      }
    },
    data() {
      return {      
        fileDialog: false,
        isInsert: false,
        fileDialogCallback: null,
        fileDialogValue: null,
        fileDialogMeta: null,
        selectedSchemaKey: null
      };
    },
   computed: {
     APIKEY_TINYMCE: function(){
       return process.env.VUE_APP_APIKEY_TINYMCE;
     },
     editorConfiguration: function(){
      //TODO: Mettere nello state il webSite selezionato per ora lo cerco nei websites per id
      let baseUrl = `${baseApiUrl}${this.cws.SiteKey}/assets/`;
      console.log(`${baseApiUrl}${this.cws.SiteKey}/`,this.currentWebSite,this.webSites);
      return {
         license_key: 'gpl',
        height: 500,
       
        document_base_url:`${baseApiUrl}${this.cws.SiteKey}/assets/` ,
        remove_script_host : false,
        relative_urls: false,
              plugins: [
      'advlist','autolink','lists','link','image','charmap','print','preview','anchor',
      'searchreplace','visualblocks','code','fullscreen',
'insertdatetime','media','table','paste','code','help','wordcount'
      ],
      toolbar:
        'undo redo | blocks fontsizeinput | bold italic | forecolor backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | link image |  code | help',
       
        file_picker_callback: this.selectFiles,  // change this value according to your HTML
        urlconverter_callback: function(url, node, on_save, name) {
          // Do some custom URL conversion
          url = url.replace(baseUrl,"../assets/")

          // Return new URL
          return url;
        }
      };
     },
     cws: function(){
       return this.webSites.find((ws) => ws.WebSiteId == this.currentWebSite );
     },
   },
   components: {
     'v-editor': Editor
   },
   methods: {
    selectFiles: function(callback, value, meta) {
      
      
      this.fileDialogCallback = callback;
      this.fileDialogValue = value;
      this.fileDialogMeta = meta;
      //console.log("this.cws",this.cws);
      this.selectedSchemaKey = this.cws.Configurations?.htmlEditor?.file_picker_for_filetype[meta.filetype]?.schema;

      this.fileDialog = true;
      // Provide file and text for the link dialog
          /*if (meta.filetype == 'file') {
            callback('mypageaaac.html', {text: 'My text'});
          }

          // Provide image and alt text for the image dialog
          if (meta.filetype == 'image') {
            callback('myimageccc.jpg', {alt: 'My alt wwtext'});
          }

          // Provide alternative source and posted for the media dialog
          if (meta.filetype == 'media') {
            callback('movie.mp4', {source2: 'alt.ogg', poster: 'image.jpg'});
          }*/
    },
    onSelect(selectedEntities) {
      console.log("onSelect selectedEntities",selectedEntities);
      if(selectedEntities != null && selectedEntities.length > 0)
      {
        let cws = this.webSites.find((ws) => ws.WebSiteId == this.currentWebSite );


        let entity = selectedEntities[0];
        let version = entity.versions[0];

        let language_code = this.language != null && this.language.languageId ?  this.language.languageId : "it";
        
        let content = version.contents.find((c) => c.webSiteLanguage.languageId == language_code);

        if(!content)
        {
          alert("immagine non presente in questa lingua!");
          this.fileDialog = false;
          return;
        }

        if(!content.slug)
        {
          alert("slug non presente in questa lingua!");
          this.fileDialog = false;
          return;
        }


        let jsonDecoded = JSON.parse(content.content);
        let jsonDecodedVersion = JSON.parse(version.content);
        

        let baseUrl = `${baseApiUrl}${cws.SiteKey}/`;

        let fileName = content.slug;
        //Verifico se lo slug contiene già l'estensione
        var extension = jsonDecodedVersion?.file?.extension?.split('.').pop() || jsonDecodedVersion.file.fileName.split('.').pop(); 
        fileName = fileName.endsWith(extension) ? fileName : `${fileName}.${extension}`;

        var retUrl = `${baseUrl}assets/${language_code}/${fileName}`;

        this.fileDialogCallback(retUrl,{alt: jsonDecoded.alt, text: jsonDecoded.abstract });
      }

      this.fileDialog = false;


    }
   }
 }
   
</script>