<template>
    <ValidationProvider v-slot="{ errors }" :name="name" :rules="validationRules">
                  
      <v-text-field
          :value="internalValue"
          :label="name"
          :required="required"          
          @input="onInput($event)"
          :error-messages="errors"
          :counter="counter"
      ></v-text-field>

    </ValidationProvider>
</template>

<script>
  import CmeaseBaseFieldMixin from './cmease-base-field-mixin'



  export default {
    mixins: [CmeaseBaseFieldMixin],    
    name: 'field-cmease-text-field',
    props: ['counter'],
  }
</script>