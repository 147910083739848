<template>
    <v-select
          :items="websites"
          :value="currentWebSite"
          item-text="Name"
          item-value="WebSiteId"
          :label="$vuetify.lang.t('$vuetify.websiteName')"
          @change="changeWebSite($event)"
          outlined
          dense
          hide-details
    ></v-select>
</template>

<script>
import { WEBSITE_UPDATECURRENTWEBSITE } from "../store/actions/website";

export default {
  computed: {
    websites () {
      //console.log(this.$store);
      return this.$store.getters.getWebSites;
    },
    currentWebSite () {
      return this.$store.getters.getCurrentWebSite;
    }
  },
  methods: {
    changeWebSite: function (value) {

      this.$store.dispatch(WEBSITE_UPDATECURRENTWEBSITE, {newWebSiteId: value}).then(() =>{
         this.$router.push('/').catch(err => {});
         this.$emit('change'); //Trigger event change on App.vue
      });
     
    }
  }
}
</script>