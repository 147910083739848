<template>
  <div>
    <ValidationProvider v-slot="{ errors }" :name="latitude" :rules="validationRules">
      <v-text-field
        v-model="lat"
        :label="latitude"
        :required="required"
        :error-messages="errors"
        @input="updategeo()"
      ></v-text-field>
    </ValidationProvider>

    <ValidationProvider v-slot="{ errors }" :name="longitude" :rules="validationRules">
      <v-text-field
        v-model="long"
        :label="longitude"
        :required="required"
        :error-messages="errors"
        @input="updategeo()"
      ></v-text-field>
    </ValidationProvider>

    <v-row align="center">
      <v-col cols="10">
        <v-text-field v-model="address" :label="address_search"></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn color="primary" block @click="codeAddress()">
          <v-icon left>mdi-map-marker-radius-outline</v-icon>
          {{ $vuetify.lang.t('$vuetify.search') }}
        </v-btn>
      </v-col>
    </v-row>

    <div class="google-map" ref="googleMap" style="width: 100%; height:500px;"></div>
  </div>
</template>


<script>
import GoogleMapsApiLoader from "google-maps-api-loader";
import CmeaseBaseFieldMixin from "./cmease-base-field-mixin";

export default {
  mixins: [CmeaseBaseFieldMixin],
  name: "field-cmease-geolocation-text-field",
  data() {
    return {
      address: null,
      address_search: "Search Address",
      latitude: "latitude",
      longitude: "longitude",
      lat:
        this.value != undefined
          ? JSON.stringify(Number(this.value["lat"]))
          : 41.902576096756384,
      long:
        this.value != undefined
          ? JSON.stringify(Number(this.value["long"]))
          : 12.495049445190425,
    };
  },
  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: process.env.VUE_APP_API_MAPS,
    });
    this.google = googleMapApi;
    this.initializeMap();
  },
  methods: {
    updategeo() {
      this.internalValue = { lat: this.lat, long: this.long };
      this.update(this.internalValue);

      this.initializeMap();
    },
    initializeMap() {
      const mapContainer = this.$refs.googleMap;

      this.map = new this.google.maps.Map(mapContainer, {
        center: { lat: Number(this.lat), lng: Number(this.long) },
        zoom: 10,
      });

      this.marker = new this.google.maps.Marker({
        draggable: true,
        position: {
          lat: Number(this.lat),
          lng: Number(this.long),
        },
      });

      this.marker.setMap(this.map);

      this.marker.addListener("dragend", () => {
        var position = JSON.stringify(this.marker.getPosition());

        this.lat = JSON.parse(position).lat;
        this.long = JSON.parse(position).lng;
        this.updategeo();
      });
    },
    codeAddress() {
      var geocoder = new this.google.maps.Geocoder();

      geocoder.geocode({ address: this.address }, (results, status) => {
        //console.log(this.address);

        if (status == "OK") {
          this.long = Number(results[0].geometry.location.lng());
          this.lat = Number(results[0].geometry.location.lat());

          /*console.log(
            "LAT: " +
              this.lat +
              " LONG: " +
              this.long +
              " FORMATTED ADDRESS: " +
              results[0].formatted_address
          );*/

          this.initializeMap();

          this.internalValue = { lat: this.lat, long: this.long };
          this.update(this.internalValue);
        } else {
          /*console.log(
            "Geocode was not successful for the following reason: " + status
          );*/
        }
      });
    },
  },
};
</script>