<template>
    <vue-form-json-schema
        v-model="internalValue"
        :schema="schema"
        :ui-schema="uiSchema"   
        @change="onChange" 
        @state-change="onChangeState"
    >   
    </vue-form-json-schema>
</template>

<script>
export default {
  name: "FormGeneratorWrapper",
  inject: ['currentWebSite','webSites'],
  props: {
    value: {},
    schema: {},
    uiSchema:{},
    language: null
  },
  provide: function () {
    return {
      language: this.language
    };
  },
  components: {
    
  },
  data() {
    return {
        internalValue : this.value      
    };
  },
  watch: {
    
  },
  computed: {
  },
  mounted() {
      console.log("FormGeneratorWrapper this.webSites",this.webSites);

  },
  methods: {  
    onChange(value) {
      this.$emit("change",value);
    },
    onChangeState(value) {
      this.$emit("state-change",value);
    },
  }
};
</script>