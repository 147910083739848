const CmeaseBaseFieldMixin = {    
    props: ['value','name','required','rules','readonly','placeholder','hint','disabled'],
    inject: ['editedItem'],
    data()
    {
        return {
            internalValue: this.value,
        }
    },
    computed: {
        validationRules: function () {
            try { 
                let retval = JSON.parse(this.rules);
                //console.log("object validation rule", retval);
                return retval; 
            } catch (e) { 
                //console.log("string valiation rule", this.rules, e);

                return this.rules;  
            } 
        }
      },
        methods: {
        onBlur: function () {
            this.$emit('blur')
        },
        onFocus: function () {
            this.$emit('focus')
        },
        onInput: function (value,key="") {
            this.update(value,"input",key);
        },
        onChange: function (value,key="") {
            this.update(value,"change",key);
        },
        onClick: function (value,key="") {            
            this.update(value,"click",key);
        },
        update(value,event="input",key="") {
          /*  console.log(
                "event",event,
                "key",key,
                "value",value,
                "internalValue",this.internalValue);*/
            if(key !== ""){
                this.internalValue = { ...this.value, [key]: value };
                this.$emit(event, { ...this.value, [key]: value });
               console.log("key 0");
            }
            else
            {
                this.internalValue = value;
                this.$emit(event,  value);
                console.log("key 1");
            }   
            
           
        }
    }
}

export default CmeaseBaseFieldMixin